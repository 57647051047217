import { css } from '@emotion/react';
import { FC, useState, ChangeEvent, FocusEvent } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { StaticImage } from 'gatsby-plugin-image';

import { HeadInner } from '../../components/Head';
import { PostConversionInfo, handlePostWithTrackingCV } from '../../util/post';
import { Layout } from '../../components/layout';
import { RegistrationLayout } from '../../components/forms/RegistrationLayout';
import { RegistrationFlow } from '../../components/organisms/RegistrationFlow';
import { colors } from '../../theme/colors';

export const Head: FC = () => {
  return (
    <HeadInner
      path='/registration/'
      title='申込み'
      description='paild（ペイルド）のお申込みはこちらから'
    />
  );
};

const RegistrationPage = () => {
  const [agree, setAgree] = useState(false);

  const handleAgree = () => {
    setAgree(!agree);
  };

  const location = useLocation();
  const env = process.env.CONTEXT;

  const portalId = process.env.HUBSPOT_ID ? process.env.HUBSPOT_ID : '';
  const formId =
    env === 'dev' || env === 'stg'
      ? 'cfab616d-f808-4abf-9b7e-50257c522780'
      : '98bbd78e-0919-4ab9-8823-8a207a1d81f5';

  const [form, setForm] = useState<PostConversionInfo>({
    email: '',
    lastname: '',
    firstname: '',
    company: '',
    employees: '',
    department: '',
    jobtitle: '',
    phone: '',
    industry: '',
    introducer: '',
    employee_expense_use: false,
    cashless_petty_cash: false,
    saas_expense_management: false,
    pageUri: location.href,
    registration: true,
    request_document: false,
    service_codes: ['prepaid_card'],
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePhoneBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setForm({
      ...form,
      phone: e.currentTarget.value.replace(/-/g, ''),
    });
  };

  const requiredList = [
    'company',
    'employees',
    'email',
    'lastname',
    'firstname',
    'department',
    'phone',
    'industry',
  ];

  const onPostButtonClick = async () => {
    await handlePostWithTrackingCV(form, portalId, formId, document.title);
    navigate('/registration/complete/');
  };

  const links = [{ name: 'paild申込みフォーム', path: '/registration/' }];

  return (
    <Layout
      header='simple'
      background='simple'
      footer='simple'
      isBottomMenu={false}
      links={links}
    >
      <RegistrationLayout
        form={form}
        onChange={handleChange}
        onCheckBoxChange={handleCheck}
        onSelectChange={handleSelectChange}
        onBlur={handlePhoneBlur}
        agree={agree}
        handleAgree={handleAgree}
        requiredList={requiredList}
        onPostButtonClick={onPostButtonClick}
      >
        <h2 css={RegistrationTitleStyle}>paild申込みフォーム</h2>
        <StaticImage
          src='../../images/registration.png'
          alt='registration'
          css={{ borderRadius: 16 }}
        />
        <div css={RegistrationDescriptionStyle}>
          <p>
            paild（ペイルド）の申込みフォームです。
            <br />
            フォーム送信後、ウォレット開設のURLをメールにてご案内いたします。
          </p>
        </div>
        <RegistrationFlow />
      </RegistrationLayout>
    </Layout>
  );
};

const RegistrationTitleStyle = css({
  color: colors.black,
  fontSize: 40,

  '@media(max-width: 1119px)': {
    fontSize: 28,
  },
});

const RegistrationDescriptionStyle = css({
  p: { fontSize: 16, lineHeight: 2, letterSpacing: 0.05 },

  '@media(max-width: 768px)': {
    br: { display: 'none' },
    p: { fontSize: 14 },
  },
});

export default RegistrationPage;
